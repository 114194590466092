import React from 'react';
import Header from '../component/Header';
import Footer from '../component/Footer';
import '../css/TermsAndConditions.css';

const Termscondition = () => {
  return (
    <>
      <Header />
      <div className="container-fluid">
        <div className="container">
          <div className="row">
            <div
              className="col-md-6 offset-md-3 text-center"
              style={{ position: "relative" }}
            >
              <div className="term-condition-container">
                <h2
                  id="Termscondition"
                >
                  Terms & Conditions
                </h2>
                <p>Greetings from FreeHydration!</p>
                <p>
                  We kindly ask that you review these Terms and Conditions
                  thoroughly before making a purchase. By placing an order with
                  FreeHydration, you consent to the terms detailed below.
                </p>

                <h3>Who We Are</h3>
                <p>
                  FreeHydration is an expert in the creation of bespoke branded
                  water cans, committed to delivering products that not only
                  meet but exceed our customers' expectations. To ensure clarity
                  and prevent any potential misunderstandings, we have
                  established the following terms and conditions.
                </p>

                <h3>Returns & Refund Policy</h3>
                <ul>
                  <li>
                    <span
                      className="spantag-li"
                    >
                      Non-Refundable Sale:{" "}
                    </span>{" "}
                    All transactions conducted through FreeHydration are
                    considered final. Once an order has been placed and
                    delivered, we do not accommodate refunds, exchanges, or
                    returns under any circumstances. We highly recommend that
                    customers conduct a thorough review of their orders prior to
                    finalizing their purchase.
                  </li>
                  <li>
                    <span
                      className="spantag-li"
                    >
                      Shipping Acknowledgment:{" "}
                    </span>{" "}
                    By placing an order, you affirm that the product aligns with
                    your expectations in terms of both water quality and
                    artistic design.
                  </li>
                </ul>

                <h3>Design creation & Colour Specifications</h3>
                <ul>
                  <li>
                    <span
                      className="spantag-li"
                    >
                      Colour Depiction:{" "}
                    </span>{" "}
                    We endeavor to provide an accurate depiction of the colours
                    associated with the artwork and colour codes supplied to us.
                    However, variations in monitor settings, lighting
                    conditions, and individual perception may result in slight
                    differences between the actual product and its digital
                    representation.
                  </li>
                  <li>
                    <span
                      className="spantag-li"
                    >
                      Colour Discrepancies:{" "}
                    </span>{" "}
                    Any minor deviations in colour between the digital display
                    and the physical product are not classified as defects and
                    will not be eligible for a refund or return after the
                    artwork has received final approval.
                  </li>
                </ul>

                <h3>Deposits Condition:</h3>
                <ul>
                  <li>
                    <span
                      className="spantag-li"
                    >
                      Advance Deposit:{" "}
                    </span>{" "}
                    To finalize your order, a non-refundable deposit is required
                    at the outset. The specific amount of this deposit will be
                    clearly detailed during the checkout process. By remitting
                    the deposit, you formally confirm your commitment to the
                    purchase.
                  </li>
                  <li>
                    <span
                      className="spantag-li"
                    >
                      Due Amount:{" "}
                    </span>{" "}
                    The outstanding balance must be paid in full prior to the
                    shipment or delivery of the product. Failure to complete
                    this payment will result in the cancellation of your order,
                    with the deposit being non-refundable.
                  </li>
                </ul>

                <h3>Order Acknowledgment & Shipping</h3>
                <ul>
                  <li>
                    <span
                      className="spantag-li"
                    >
                      Order Verification:{" "}
                    </span>{" "}
                    Upon confirming your order, you will receive Design creation
                    proofs for your approval. It is your responsibility to
                    meticulously review all order details, ensuring that every
                    aspect, including text and colour specifications, is
                    accurate and satisfactory.
                  </li>
                  <li>
                    <span
                      className="spantag-li"
                    >
                      Shipping Time:{" "}
                    </span>{" "}
                    We will exert our utmost efforts to deliver your product
                    within the projected timeframe of 2 to 4 weeks. However,
                    delivery times may vary due to factors such as location and
                    unforeseen circumstances, and we are unable to guarantee a
                    specific delivery date.
                  </li>
                  <li>
                    <span
                      className="spantag-li"
                    >
                      Damage Tolerance:{" "}
                    </span>{" "}
                    We strive to ensure that all cans arrive in impeccable
                    condition. Nevertheless, due to the inherent nature of
                    shipping, a tolerance of up to (3% - 8%) of the total cans
                    in any shipment of 1 pallet (between 2,400 - 4,000 cans) may
                    sustain damage without eligibility for refund or
                    replacement. This allowance is considered acceptable and is
                    consistent with standard industry practices regarding
                    transit-related damages.
                  </li>
                </ul>

                <h3>Customer Obligations</h3>
                <ul>
                  <li>
                    <span
                      className="spantag-li"
                    >
                      Provision of Accurate Information:{" "}
                    </span>{" "}
                    It is the customer's obligation to provide precise and
                    comprehensive information when placing an order.
                    FreeHydration disclaims any liability for delivery errors
                    that arise from incorrect information supplied by the
                    customer.
                  </li>
                  <li>
                    <span
                      className="spantag-li"
                    >
                      Acknowledgment of Terms:{" "}
                    </span>{" "}
                    By finalizing your purchase, you acknowledge that you have
                    read, comprehended, and accepted these Terms and Conditions
                    in their entirety.
                  </li>
                </ul>

                <h3>Modifications to Terms & Conditions</h3>
                <ul>
                  <li>
                    <span
                      className="spantag-li"
                    >
                      Revisions:
                    </span>{" "}
                    FreeHydration retains the right to revise these Terms and
                    Conditions at any time without prior notice. Any
                    modifications will be posted on our website and will be
                    applicable to all future orders.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Termscondition;
