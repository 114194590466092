import React, { useEffect }  from 'react'
import AOS from 'aos';
import 'aos/dist/aos.css';
import YourComponent from '../component/YourComponent';
import Header from '../component/Header';
import Footer from '../component/Footer';
import YourComponent2 from '../component/YourComponent2';
import YourComponent3 from '../component/YourComponent3';
import GetQuote from '../component/GetQuote';

const Advestisement = () => {
    useEffect(() => {
        AOS.init();
      }, []);
  return (
    <div
      style={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}
    >
      <div style={{flex:"1"}}>
        <Header />
        <YourComponent />
        <YourComponent2 />
        <YourComponent3 />
        <GetQuote />
        <Footer />
      </div>
    </div>
  );
}

export default Advestisement