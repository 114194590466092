import React from 'react';
import 'aos/dist/aos.css';  
import '../css/YourComponent.css';
import img from '../images/Untitled Cans Advertisment  - Made with PosterMyWall22.jpg'
const YourComponent2 = () => {
  return (
    <div className="container pb-5">
      <div className="row">
        <div className="col-md-8">
          <h3
            className="text-center"
            style={{ color: '#22B1DF' }}
            data-aos="zoom-in-down"
          >
            Our product acts as a blank canvas
          </h3>
          <div
            className="mt-3 mb-3 border-bottom"
            style={{ width: '50%', margin: 'auto' }}
          ></div>
          <p
            className="text-justify"
            style={{ color: '#22B1DF' }}
            data-aos="zoom-in-down"
          >
            Distinguishing us from conventional advertising that often frustrates audiences. Rather than inducing discomfort,
            we strive to evoke joy, creating an atmosphere of positivity that motivates consumers to enthusiastically engage with your brand.
          </p>
          <div
            className="mt-3 mb-3 border-bottom"
            style={{ width: '50%', margin: 'auto' }}
          ></div>
        <div className="row cardingg">
  <div className="col-md-6 menin text-center">
    <div className="card p-4 m-2 bg-light shadow-sm" data-aos="zoom-in-down">
      <h4 style={{ color: '#22B1DF' }}>Employ QR codes </h4>
      <div className="mt-3 mb-3 border-bottom" style={{ width: '50%', margin: 'auto' }}></div>
      <p style={{ color: '#22B1DF' }}>
      to seamlessly connect to diverse online content, such as websites, promotional coupons, and multimedia videos.
      </p>
    </div>
  </div>
  <div className="col-md-6 menin text-center" data-aos="zoom-in-down">
    <div className="card p-4 m-2 bg-light shadow-sm">
      <h4 style={{ color: '#22B1DF' }}>Market or generously Distribute </h4>
      <div className="mt-3 mb-3 border-bottom" style={{ width: '50%', margin: 'auto' }}></div>
      <p style={{ color: '#22B1DF' }}>
      ad-supported FreeHydration in aluminum cans and paper cartons.
      </p>
    </div>
  </div>
  <div className="col-md-6 menin text-center mt-4" data-aos="zoom-in-down">
    <div className="card p-4 m-2 bg-light shadow-sm">
      <h4 style={{ color: '#22B1DF' }}>Select a distribution model</h4>
      <div className="mt-3 mb-3 border-bottom" style={{ width: '50%', margin: 'auto' }}></div>
      <p style={{ color: '#22B1DF' }}>
      Based on whether you would like us to handle the distribution or ship it directly to your address.
      </p>
    </div>
  </div>
  <div className="col-md-6 menin text-center mt-4" data-aos="zoom-in-down">
    <div className="card p-4 m-2 bg-light shadow-sm">
      <h4 style={{ color: '#22B1DF' }}>
      Subsidize or entirely offset the costs 
      </h4>
      <div className="mt-3 mb-3 border-bottom" style={{ width: '50%', margin: 'auto' }}></div>
      <br />
      <p style={{ color: '#22B1DF' }}>By collaborating with non-competing brands to share the advertising space.</p>
    </div>
  </div>
  <div className="col-md-12">
    <div className="button-container mt-4" data-aos="zoom-in-down">
      <a href="#getQuote" className="btn">
        Advertise with us
      </a>
    </div>
  </div>
</div>

        </div>
        <div className="col-md-4 d-flex justify-content-center" data-aos="zoom-in-down">
          <img
            src={img}
            className="img-fluid"
            style={{ maxHeight: '700px',width: '220px',marginTop: '20px'}}
            alt="Red Can"
          />
        </div>
      </div>
    </div>
  );
};

export default YourComponent2;