import React, { useEffect } from 'react';
import 'aos/dist/aos.css';
import AOS from 'aos';
import img from '../images/cost_no_background_animated.gif'
import img1 from '../images/natural-product.gif'
import img2 from '../images/effervescent.gif'
import img3 from '../images/medical-insurance-transparent.gif'
import img4 from '../images/boy_drinking_water.jpg'
import img5 from '../images/crushed_bottle.jpg'
import img6 from '../images/step01.webp';
import designimg from '../images/designing.webp';
import production from '../images/Productionimage2.jpeg'
import { Link } from 'react-router-dom';
import '../css/HydrationSection.css'
const HydrationSection = () => {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  return (
    <>
      <div className="container-fluid p-3 mt-4">
        <div className="container">
          <div className="row">
            <div className="col-md-8 offset-md-2">
              <div className="container">
                <div className="row text-center">
                  <div className="col-md-6 bg-light p-5" data-aos="fade-up">
                    <img
                      src={img}
                      alt="Save Water"
                      className="img-fluid"
                      style={{ maxWidth: "100px" }}
                    />
                    <h3 className="m-3">Save Money</h3>
                    <p>Bottled water is more expensive than tap water.</p>
                  </div>
                  <div className="col-md-6 p-5" data-aos="fade-up">
                    <img
                      src={img1}
                      alt="Eco Friendly"
                      className="img-fluid"
                      style={{ maxWidth: "100px" }}
                    />
                    <h3 className="m-3">Eco Friendly</h3>
                    <p>Our goal is to address global warming.</p>
                  </div>
                  <div className="col-md-6 p-5" data-aos="fade-up">
                    <img
                      src={img2}
                      alt="Premium Water"
                      className="img-fluid"
                      style={{ maxWidth: "100px" }}
                    />
                    <h3 className="m-3">Premium Water</h3>
                    <p>
                      FreeHydration provides natural still water, and all our
                      containers are made of either boxes or cans.
                    </p>
                  </div>
                  <div className="col-md-6 p-5 bg-light" data-aos="fade-up">
                    <img
                      src={img3}
                      alt="Save Lives"
                      className="img-fluid"
                      style={{ maxWidth: "100px" }}
                    />
                    <h3 className="m-3">Save Lives</h3>
                    <p>
                      Ten cents from every beverage distribute is donated to
                      charity to assist those in need.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="three-steps-container p-3"
        data-aos="fade-up"
        data-aos-duration="1200"
      >
        <h1
          data-aos="fade-right"
          data-aos-duration="1000"
          className="text-center mb-5 main-txtclr-change"
        >
          Three Steps to Reach Your Customer!!!
        </h1>

        <div className="row justify-content-center">
          {/* Step 1 */}
          <div className="col-md-4 mb-5">
            <div className="flip-card" data-aos="fade-up" data-aos-delay="200">
              <div className="flip-card-inner">
                <div className="flip-card-front text-center p-3">
                  <img
                    src={img6}
                    alt="Optimize the Market"
                    className="img-fluid flip-image"
                  />
                  <h4 className="mt-3 step-title">Market Optimization</h4>
                </div>
                <div className="flip-card-back p-2">
                  <h4 className="step-title">Market Optimization</h4>
                  <p>
                    When a brand collaborates with us, we strategically identify
                    the most effective locations for distributing the cans,
                    whether in public venues or during events.
                  </p>
                </div>
              </div>
            </div>
          </div>

          {/* Step 2 */}
          <div className="col-md-4 mb-5">
            <div className="flip-card" data-aos="fade-up" data-aos-delay="400">
              <div className="flip-card-inner">
                <div className="flip-card-front text-center p-3">
                  <img
                    src={designimg}
                    alt="Designing"
                    className="img-fluid flip-image"
                  />
                  <h4 className="mt-3 step-title">Designing</h4>
                </div>
                <div className="flip-card-back p-2">
                  <h4 className="step-title">Designing</h4>
                  <p>
                    Next, we transition to the design phase, where our expert
                    graphic design team develops striking advertisements for the
                    cans. Should you have an existing brand design, we can
                    expertly incorporate it into our creations.
                  </p>
                </div>
              </div>
            </div>
          </div>

          {/* Step 3 */}
          <div className="col-md-4 mb-5">
            <div className="flip-card" data-aos="fade-up" data-aos-delay="600">
              <div className="flip-card-inner">
                <div className="flip-card-front text-center p-3">
                  <img
                    src={production}
                    alt="Production"
                    className="img-fluid flip-image"
                  />
                  <h4 className="mt-3 step-title">Production</h4>
                </div>
                <div className="flip-card-back p-2">
                  <h4 className="step-title">Production</h4>
                  <p>
                    After the design phase, we progress to the manufacturing
                    stage, where the cans are meticulously produced and
                    packaged. Within an estimated timeframe of 2 to 4 weeks, the
                    final product will be primed for distribution.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid p-3 mt-4">
        <div className="container p-0">
          <div className="row p-5">
            <div className="col-md-6 d-flex align-items-center justify-content-center mb-5">
              <div>
                <h3 style={{ color: "#22B1DF" }} data-aos="fade-up">
                  We are partnered with <br />
                  NGO (Non-Governmental Organization)
                </h3>
                <div
                  className="mt-3 mb-3 border-bottom"
                  style={{ width: "100%" }}
                ></div>
                <p className="hydration-p-heading" data-aos="fade-up">
                  We donate ten cents per beverage to an NGO. Each FreeHydration
                  drinks contributes 10 cents to support NGOs in helping
                  homeless individuals stay safe and healthy.
                </p>
                <div className="button-container" data-aos="fade-up">
                  <Link
                    to={"http://www.google.com"}
                    target="_blank"
                    rel="noreferrer"
                    className="btn"
                  >
                    LEARN MORE
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <img
                src={img4}
                data-aos="zoom-in-down"
                alt="Boy Drinking Water"
                className="img-fluid"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid p-3 mt-4">
        <div className="bg-light" style={{ width: "100%" }}>
          <div className="container">
            <div className="row p-4 mt-5">
              <div className="col-md-6">
                <img
                  src={img5}
                  alt="Crushed Bottle"
                  className="img-fluid"
                  data-aos="fade-up"
                />
              </div>
              <div className="col-md-6 p-5 d-flex align-items-center justify-content-center">
                <div>
                  <h3 style={{ color: "#22B1DF" }} data-aos="fade-up">
                    We eschew the use of plastic bottles entirely
                  </h3>
                  <div
                    className="mt-3 mb-3 border-bottom"
                    style={{ width: "50%" }}
                  ></div>
                  <p className="hydration-p-heading" data-aos="fade-up">
                    FreeHydration is offered in aluminum cans and paper cartons
                    of natural still water, reflecting our commitment to
                    providing water freely while avoiding plastic packaging.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HydrationSection;