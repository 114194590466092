import React from 'react';
import 'aos/dist/aos.css';
import AOS from 'aos';
import { useEffect } from 'react';
import img from '../images/hand3.png'
import '../css/Banner.css'
import { Link } from 'react-router-dom';

const Banner = () => {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  return (
    <>
    <div
      className="container-fluid banner-container"
      id='banner'
    >
      <div className="container">
        <div className="row">
          <div className="col-md-6" style={{ position: 'relative' }}>
            <img
              src={img}
              alt="Hand"
              data-aos="zoom-in-down"
              className="img-fluid  bannerimage-1"
            />
          </div>
          <div
            className="col-md-6 d-flex flex-column justify-content-center align-items-center"
            style={{ height: '460px' }}
          >
            <h1
              className="text-center banner-heading1"
              data-aos="zoom-in-down"
            >
              Your Brand In Everyone's Hand!
            </h1>
            <div className="button-container mb-5" data-aos="zoom-in-down">
              <Link to='/Advertise' className="btn">
                ADVERTISE WITH US
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  );
};

export default Banner;
