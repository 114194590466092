import React, { useState, useEffect } from "react";
import "../../css/Login.css";
import Adminpanel from './Adminpanel';
import { Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import Swal from 'sweetalert2';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BASE_SERVER_URL } from "../../utils/Api";
import { FaEye, FaEyeSlash } from 'react-icons/fa';

const ResetPass = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [oldPass, setOldPass] = useState("");
  const [newPass, setNewPass] = useState("");
  const [loading, setLoading] = useState(false);
  const [showOldPass, setShowOldPass] = useState(false);
  const [showNewPass, setShowNewPass] = useState(false);
  const navigate = useNavigate();


  const handleSidebarToggle = (isOpen) => {
    setIsSidebarOpen(isOpen);
  };


  const toggleOldPassVisibility = () => {
    setShowOldPass((prevState) => !prevState);
  };


  const toggleNewPassVisibility = () => {
    setShowNewPass((prevState) => !prevState);
  };


const resetPassword = async (e) => {
  e.preventDefault();
  

  Swal.fire({
    title: "Are you sure?",
    text: "Do you really want to reset your password?",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Yes, reset it!",
    cancelButtonText: "Cancel",
  }).then(async (result) => {
    if (result.isConfirmed) {
      setLoading(true);
      try {
        const response = await fetch(`${BASE_SERVER_URL}/edit`, {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            token: `${localStorage.getItem("token")}`,
          },
          body: JSON.stringify({ oldPass, newPass }),
        });

        const data = await response.json();
        if (response.ok) {
          Swal.fire("Reset!", "Your password has been reset.", "success");
          setTimeout(() => {
            navigate("/Dashboard");
          }, 1000);
        } else {
          toast.error(data.message || "Password reset failed");
        }
      } catch (error) {
        toast.error("An error occurred. Please try again.");
      } finally {
        setLoading(false);
      }
    }
  });
};

  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true,
    });
    AOS.refresh();
  }, []);

  return (
    <>
     <Adminpanel onToggleSidebar={handleSidebarToggle} />
      <div className={`contactget-container ${isSidebarOpen ? "open" : "close"}`}>
      <div className="login-container">
        <form className="login-form" onSubmit={resetPassword}>
          <h2 className="login-title">Reset Password</h2>

          <div className="input-group" data-aos="zoom-out-down">
            <label htmlFor="oldPass" className="input-label">
              Old Password
            </label>
            <div className="password-container">
              <input
                type={showOldPass ? "text" : "password"}
                id="oldPass"
                className="input-field"
                placeholder="Enter your old password"
                value={oldPass}
                onChange={(e) => setOldPass(e.target.value)}
                required
                autoComplete="current-password"
              />
              <span className="eye-icon" onClick={toggleOldPassVisibility}>
                {showOldPass ? <FaEyeSlash /> : <FaEye />}
              </span>
            </div>
          </div>

          <div className="input-group" data-aos="zoom-out-down">
            <label htmlFor="newPass" className="input-label">
              New Password
            </label>
            <div className="password-container">
              <input
                type={showNewPass ? "text" : "password"}
                id="newPass"
                className="input-field"
                placeholder="Enter your new password"
                value={newPass}
                onChange={(e) => setNewPass(e.target.value)}
                required
                autoComplete="new-password"
              />
              <span className="eye-icon" onClick={toggleNewPassVisibility}>
                {showNewPass ? <FaEyeSlash /> : <FaEye />}
              </span>
            </div>
          </div>

          <button
            type="submit"
            className="login-button"
            data-aos="zoom-out-down"
            disabled={loading}
          >
            {loading ? (
              <>
                <Spinner animation="border" size="sm" />
                <span className="ms-2">Resetting...</span>
              </>
            ) : (
              "Reset Password"
            )}
          </button>
        </form>
      </div>
     </div>
      <ToastContainer />
    </>
  );
};

export default ResetPass;
