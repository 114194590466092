import React from 'react'
import Header from '../component/Header'
import Banner from '../component/Banner'
import FreeHydration from '../component/Freehydration'
import HydrationSection from '../component/HydrationSection '
import SubscribeForm from '../component/SubscribeForm'
import Footer from '../component/Footer'

const Home = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh"
      }}
    >
      <div style={{flex:"1"}}>
        <Header />
        <Banner />
        <FreeHydration />
        <HydrationSection />
        <SubscribeForm />
        <Footer />
      </div>
    </div>
  );
}

export default Home