import React from 'react';
import 'aos/dist/aos.css';
import AOS from 'aos';
import { useEffect } from 'react';
import "../css/Banner.css";
import img from '../images/banner_both2.png'

const FreeHydration = () => {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  return (
    <div className="p-4 rounded shadow-sm bg-light">
      <div className="container p-0">
        <div className="row">
          <div className="col-md-5">
            <img
              src={img}
              alt="Banner"
              data-aos="fade-up"
              className="img-fluid"
            />
          </div>
          <div className="col-md-7 d-flex align-items-center justify-content-center">
            <div className="p-3 bg-light rounded shadow">
              <h4 className='freehyration-heading1'
                data-aos="fade-up"
              >
                FreeHydration is an{' '}
                <span style={{ color: 'limegreen' }}>innovative advertising platform</span>{' '}
                that transforms the art of promotion by using{' '}
                <span style={{ color: 'limegreen' }}>premium still water</span>{' '}
                as a distinctive canvas for businesses to display their brand. Plus, we are committed to making hydration accessible to everyone,{' '}
                <span style={{ color: 'limegreen' }}>at no cost</span>. Seize the chance to deliver your message directly to potential customers!
              </h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default FreeHydration;
