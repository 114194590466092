import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import { Alert, Container, Spinner } from "react-bootstrap";
import "../css/YourComponent.css";
import Swal from 'sweetalert2';
import img from "../images/contact-advertise.png";
import { BASE_SERVER_URL } from "../utils/Api";

const GetQuote = () => {
  const imgRef = useRef(null);
  const formRef = useRef(null);

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    ccode: "IE +353",
    phone: "",
    company: "",
    message: "",
  });

  const [formError, setFormError] = useState("");
  const [formSuccess, setFormSuccess] = useState("");
  const [loading, setLoading] = useState(false);
  const [prevScrollPos, setPrevScrollPos] = useState(window.pageYOffset);


  const validateEmail = (email) => {
    const regex = /^[a-zA-Z0-9._-]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };


  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset;
      const scrollDirection = currentScrollPos > prevScrollPos ? "down" : "up";
      setPrevScrollPos(currentScrollPos);

      if (scrollDirection === "down") {
        const observer = new IntersectionObserver(
          (entries) => {
            entries.forEach((entry) => {
              if (entry.isIntersecting) {
                entry.target.classList.add("zoom-in-down");
              }
            });
          },
          { threshold: 0.5 }
        );

        if (imgRef.current) observer.observe(imgRef.current);
        if (formRef.current) observer.observe(formRef.current);

        return () => {
          if (imgRef.current) observer.unobserve(imgRef.current);
          if (formRef.current) observer.unobserve(formRef.current);
        };
      } else {
        if (imgRef.current) imgRef.current.classList.remove("zoom-in-down");
        if (formRef.current) formRef.current.classList.remove("zoom-in-down");
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [prevScrollPos]);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (
      !formData.name ||
      !formData.email ||
      !formData.ccode ||
      !formData.phone
    ) {
      setFormError("All fields are required.");
      return;
    }


  if (!validateEmail(formData.email)) {
    setFormError("Please enter a valid email address.");
    return;
    }
    

    setFormError("");
    setFormSuccess("");
    setLoading(true);


    try {
      const apiUrl =`${BASE_SERVER_URL}/quotes`;
      const response = await axios.post(apiUrl, formData);

      Swal.fire('Success', response.data.message, 'success');
      setFormSuccess("Form submitted successfully!");
      // console.log("Form submitted:", response.data);

      setFormData({
        name: "",
        email: "",
        ccode: "IE +353",
        phone: "",
        company: "",
        message: "",
      });
    } catch (error) {
      setFormError("An error occurred. Please try again.");
      Swal.fire('Error', 'An error occurred. Please try again.', 'error');
      // console.error("Error submitting form:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Container>
        <div className="container" id="getQuote">
          <div className="row">
            <div className="col-md-6 text-center" ref={imgRef}>
              <img src={img} alt="Contact Advertise" className="img-fluid" />
            </div>
            <div
              className="col-md-5 pt-5"
              style={{ marginBottom: "80px" }}
              ref={formRef}
            >
              {formError && <Alert variant="danger">{formError}</Alert>}
              {formSuccess && <Alert variant="success">{formSuccess}</Alert>}
              <h1>Get a Quote</h1>
              <p>We endeavor to respond as promptly as possible.</p>
              <form onSubmit={handleSubmit} id="myformTwo">
                <div className="mb-3">
                  <label htmlFor="name" className="form-label">
                    Name<span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    name="name"
                    className="form-control"
                    placeholder="Name"
                    value={formData.name}
                    onChange={handleChange}
                    required
                  />
                </div>

                <div className="mb-3">
                  <label htmlFor="email" className="form-label">
                    Email<span className="text-danger">*</span>
                  </label>
                  <input
                    type="email"
                    name="email"
                    className="form-control"
                    placeholder="Email"
                    value={formData.email}
                    onChange={handleChange}
                    required
                  />
                </div>

                <div className="mb-3">
                  <div className="row">
                    <div className="col-md-3">
                      <label htmlFor="ccode" className="form-label">
                        Code<span className="text-danger">*</span>
                      </label>
                      <select
                        className="form-control"
                        name="ccode"
                        value={formData.ccode}
                        onChange={handleChange}
                        required
                      >
                        <option
                          value="IE +353"
                          className="R4tv1w"
                          aria-selected="true"
                        >
                          IE +353
                        </option>
                        <option
                          value="AW +297"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          AW +297
                        </option>
                        <option
                          value="AO +244"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          AO +244
                        </option>
                        <option
                          value="AF +93"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          AF +93
                        </option>
                        <option
                          value="AI +1"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          AI +1
                        </option>
                        <option
                          value="AX +358"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          AX +358
                        </option>
                        <option
                          value="AL +355"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          AL +355
                        </option>
                        <option
                          value="AD +376"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          AD +376
                        </option>
                        <option
                          value="AE +971"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          AE +971
                        </option>
                        <option
                          value="AR +54"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          AR +54
                        </option>
                        <option
                          value="AM +374"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          AM +374
                        </option>
                        <option
                          value="AS +1684"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          AS +1684
                        </option>
                        <option
                          value="AQ +672"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          AQ +672
                        </option>
                        <option
                          value="TF +262"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          TF +262
                        </option>
                        <option
                          value="AG +1"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          AG +1
                        </option>
                        <option
                          value="AU +61"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          AU +61
                        </option>
                        <option
                          value="AT +43"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          AT +43
                        </option>
                        <option
                          value="AZ +994"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          AZ +994
                        </option>
                        <option
                          value="BI +257"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          BI +257
                        </option>
                        <option
                          value="BE +32"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          BE +32
                        </option>
                        <option
                          value="BJ +229"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          BJ +229
                        </option>
                        <option
                          value="BQ +599"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          BQ +599
                        </option>
                        <option
                          value="BF +226"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          BF +226
                        </option>
                        <option
                          value="BD +880"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          BD +880
                        </option>
                        <option
                          value="BG +359"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          BG +359
                        </option>
                        <option
                          value="BH +973"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          BH +973
                        </option>
                        <option
                          value="BS +1"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          BS +1
                        </option>
                        <option
                          value="BA +387"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          BA +387
                        </option>
                        <option
                          value="BL +590"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          BL +590
                        </option>
                        <option
                          value="BY +375"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          BY +375
                        </option>
                        <option
                          value="BZ +501"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          BZ +501
                        </option>
                        <option
                          value="BM +1"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          BM +1
                        </option>
                        <option
                          value="BO +591"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          BO +591
                        </option>
                        <option
                          value="BR +55"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          BR +55
                        </option>
                        <option
                          value="BB +1246"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          BB +1246
                        </option>
                        <option
                          value="BN +673"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          BN +673
                        </option>
                        <option
                          value="BT +975"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          BT +975
                        </option>
                        <option
                          value="BV +55"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          BV +55
                        </option>
                        <option
                          value="BW +267"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          BW +267
                        </option>
                        <option
                          value="CF +236"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          CF +236
                        </option>
                        <option
                          value="CA +1"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          CA +1
                        </option>
                        <option
                          value="CC +61"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          CC +61
                        </option>
                        <option
                          value="CH +41"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          CH +41
                        </option>
                        <option
                          value="CL +56"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          CL +56
                        </option>
                        <option
                          value="CN +86"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          CN +86
                        </option>
                        <option
                          value="CI +225"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          CI +225
                        </option>
                        <option
                          value="CM +237"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          CM +237
                        </option>
                        <option
                          value="CD +243"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          CD +243
                        </option>
                        <option
                          value="CG +242"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          CG +242
                        </option>
                        <option
                          value="CK +682"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          CK +682
                        </option>
                        <option
                          value="CO +57"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          CO +57
                        </option>
                        <option
                          value="KM +269"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          KM +269
                        </option>
                        <option
                          value="CV +238"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          CV +238
                        </option>
                        <option
                          value="CR +506"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          CR +506
                        </option>
                        <option
                          value="CW +599"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          CW +599
                        </option>
                        <option
                          value="CX +61"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          CX +61
                        </option>
                        <option
                          value="KY +1"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          KY +1
                        </option>
                        <option
                          value="CY +357"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          CY +357
                        </option>
                        <option
                          value="CZ +420"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          CZ +420
                        </option>
                        <option
                          value="DE +49"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          DE +49
                        </option>
                        <option
                          value="DJ +253"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          DJ +253
                        </option>
                        <option
                          value="DM +1"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          DM +1
                        </option>
                        <option
                          value="DK +45"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          DK +45
                        </option>
                        <option
                          value="DO +1"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          DO +1
                        </option>
                        <option
                          value="DZ +213"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          DZ +213
                        </option>
                        <option
                          value="EC +593"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          EC +593
                        </option>
                        <option
                          value="EG +20"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          EG +20
                        </option>
                        <option
                          value="ER +291"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          ER +291
                        </option>
                        <option
                          value="EH +212"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          EH +212
                        </option>
                        <option
                          value="ES +34"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          ES +34
                        </option>
                        <option
                          value="EE +372"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          EE +372
                        </option>
                        <option
                          value="ET +251"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          ET +251
                        </option>
                        <option
                          value="FI +358"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          FI +358
                        </option>
                        <option
                          value="FJ +679"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          FJ +679
                        </option>
                        <option
                          value="FK +500"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          FK +500
                        </option>
                        <option
                          value="FR +33"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          FR +33
                        </option>
                        <option
                          value="FO +298"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          FO +298
                        </option>
                        <option
                          value="FM +691"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          FM +691
                        </option>
                        <option
                          value="GA +241"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          GA +241
                        </option>
                        <option
                          value="GB +44"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          GB +44
                        </option>
                        <option
                          value="GE +995"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          GE +995
                        </option>
                        <option
                          value="GG +44"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          GG +44
                        </option>
                        <option
                          value="GH +233"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          GH +233
                        </option>
                        <option
                          value="GI +350"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          GI +350
                        </option>
                        <option
                          value="GN +224"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          GN +224
                        </option>
                        <option
                          value="GP +590"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          GP +590
                        </option>
                        <option
                          value="GM +220"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          GM +220
                        </option>
                        <option
                          value="GW +245"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          GW +245
                        </option>
                        <option
                          value="GQ +240"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          GQ +240
                        </option>
                        <option
                          value="GR +30"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          GR +30
                        </option>
                        <option
                          value="GD +1"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          GD +1
                        </option>
                        <option
                          value="GL +299"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          GL +299
                        </option>
                        <option
                          value="GT +502"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          GT +502
                        </option>
                        <option
                          value="GF +594"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          GF +594
                        </option>
                        <option
                          value="GU +1"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          GU +1
                        </option>
                        <option
                          value="GY +592"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          GY +592
                        </option>
                        <option
                          value="HK +852"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          HK +852
                        </option>
                        <option
                          value="HM +672"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          HM +672
                        </option>
                        <option
                          value="HN +504"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          HN +504
                        </option>
                        <option
                          value="HR +385"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          HR +385
                        </option>
                        <option
                          value="HT +509"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          HT +509
                        </option>
                        <option
                          value="HU +36"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          HU +36
                        </option>
                        <option
                          value="ID +62"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          ID +62
                        </option>
                        <option
                          value="IM +44"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          IM +44
                        </option>
                        <option
                          value="IN +91"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          IN +91
                        </option>
                        <option
                          value="IO +246"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          IO +246
                        </option>
                        <option
                          value="IE +353"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          IE +353
                        </option>
                        <option
                          value="IS +354"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          IS +354
                        </option>
                        <option
                          value="IL +972"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          IL +972
                        </option>
                        <option
                          value="IT +39"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          IT +39
                        </option>
                        <option
                          value="JM +1876"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          JM +1876
                        </option>
                        <option
                          value="JE +44"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          JE +44
                        </option>
                        <option
                          value="JO +962"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          JO +962
                        </option>
                        <option
                          value="JP +81"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          JP +81
                        </option>
                        <option
                          value="KZ +7"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          KZ +7
                        </option>
                        <option
                          value="KE +254"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          KE +254
                        </option>
                        <option
                          value="KG +996"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          KG +996
                        </option>
                        <option
                          value="KH +855"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          KH +855
                        </option>
                        <option
                          value="KI +686"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          KI +686
                        </option>
                        <option
                          value="KN +1"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          KN +1
                        </option>
                        <option
                          value="KR +82"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          KR +82
                        </option>
                        <option
                          value="XK +383"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          XK +383
                        </option>
                        <option
                          value="KW +965"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          KW +965
                        </option>
                        <option
                          value="LA +856"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          LA +856
                        </option>
                        <option
                          value="LB +961"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          LB +961
                        </option>
                        <option
                          value="LR +231"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          LR +231
                        </option>
                        <option
                          value="LY +218"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          LY +218
                        </option>
                        <option
                          value="LC +1"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          LC +1
                        </option>
                        <option
                          value="LI +423"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          LI +423
                        </option>
                        <option
                          value="LK +94"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          LK +94
                        </option>
                        <option
                          value="LS +266"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          LS +266
                        </option>
                        <option
                          value="LT +370"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          LT +370
                        </option>
                        <option
                          value="LU +352"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          LU +352
                        </option>
                        <option
                          value="LV +371"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          LV +371
                        </option>
                        <option
                          value="MO +853"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          MO +853
                        </option>
                        <option
                          value="MF +590"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          MF +590
                        </option>
                        <option
                          value="MA +212"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          MA +212
                        </option>
                        <option
                          value="MC +377"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          MC +377
                        </option>
                        <option
                          value="MD +373"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          MD +373
                        </option>
                        <option
                          value="MG +261"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          MG +261
                        </option>
                        <option
                          value="MV +960"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          MV +960
                        </option>
                        <option
                          value="MX +52"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          MX +52
                        </option>
                        <option
                          value="MH +692"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          MH +692
                        </option>
                        <option
                          value="MK +389"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          MK +389
                        </option>
                        <option
                          value="ML +223"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          ML +223
                        </option>
                        <option
                          value="MT +356"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          MT +356
                        </option>
                        <option
                          value="MM +95"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          MM +95
                        </option>
                        <option
                          value="ME +382"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          ME +382
                        </option>
                        <option
                          value="MN +976"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          MN +976
                        </option>
                        <option
                          value="MP +1670"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          MP +1670
                        </option>
                        <option
                          value="MZ +258"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          MZ +258
                        </option>
                        <option
                          value="MR +222"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          MR +222
                        </option>
                        <option
                          value="MS +1"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          MS +1
                        </option>
                        <option
                          value="MQ +596"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          MQ +596
                        </option>
                        <option
                          value="MU +230"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          MU +230
                        </option>
                        <option
                          value="MW +265"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          MW +265
                        </option>
                        <option
                          value="MY +60"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          MY +60
                        </option>
                        <option
                          value="YT +262"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          YT +262
                        </option>
                        <option
                          value="NA +264"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          NA +264
                        </option>
                        <option
                          value="NC +687"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          NC +687
                        </option>
                        <option
                          value="NE +227"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          NE +227
                        </option>
                        <option
                          value="NF +672"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          NF +672
                        </option>
                        <option
                          value="NG +234"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          NG +234
                        </option>
                        <option
                          value="NI +505"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          NI +505
                        </option>
                        <option
                          value="NU +683"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          NU +683
                        </option>
                        <option
                          value="NL +31"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          NL +31
                        </option>
                        <option
                          value="NO +47"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          NO +47
                        </option>
                        <option
                          value="NP +977"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          NP +977
                        </option>
                        <option
                          value="NR +674"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          NR +674
                        </option>
                        <option
                          value="NZ +64"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          NZ +64
                        </option>
                        <option
                          value="OM +968"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          OM +968
                        </option>
                        <option
                          value="PK +92"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          PK +92
                        </option>
                        <option
                          value="PA +507"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          PA +507
                        </option>
                        <option
                          value="PN +64"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          PN +64
                        </option>
                        <option
                          value="PE +51"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          PE +51
                        </option>
                        <option
                          value="PH +63"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          PH +63
                        </option>
                        <option
                          value="PW +680"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          PW +680
                        </option>
                        <option
                          value="PG +675"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          PG +675
                        </option>
                        <option
                          value="PL +48"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          PL +48
                        </option>
                        <option
                          value="PR +1"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          PR +1
                        </option>
                        <option
                          value="PT +351"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          PT +351
                        </option>
                        <option
                          value="PY +595"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          PY +595
                        </option>
                        <option
                          value="PS +970"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          PS +970
                        </option>
                        <option
                          value="PF +689"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          PF +689
                        </option>
                        <option
                          value="QA +974"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          QA +974
                        </option>
                        <option
                          value="RE +262"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          RE +262
                        </option>
                        <option
                          value="RO +40"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          RO +40
                        </option>
                        <option
                          value="RU +7"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          RU +7
                        </option>
                        <option
                          value="RW +250"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          RW +250
                        </option>
                        <option
                          value="SA +966"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          SA +966
                        </option>
                        <option
                          value="SD +249"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          SD +249
                        </option>
                        <option
                          value="SN +221"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          SN +221
                        </option>
                        <option
                          value="SG +65"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          SG +65
                        </option>
                        <option
                          value="GS +500"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          GS +500
                        </option>
                        <option
                          value="SH +290"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          SH +290
                        </option>
                        <option
                          value="SJ +47"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          SJ +47
                        </option>
                        <option
                          value="SB +677"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          SB +677
                        </option>
                        <option
                          value="SL +232"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          SL +232
                        </option>
                        <option
                          value="SV +503"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          SV +503
                        </option>
                        <option
                          value="SM +378"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          SM +378
                        </option>
                        <option
                          value="SO +252"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          SO +252
                        </option>
                        <option
                          value="PM +508"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          PM +508
                        </option>
                        <option
                          value="RS +381"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          RS +381
                        </option>
                        <option
                          value="SS +211"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          SS +211
                        </option>
                        <option
                          value="ST +239"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          ST +239
                        </option>
                        <option
                          value="SR +597"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          SR +597
                        </option>
                        <option
                          value="SK +421"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          SK +421
                        </option>
                        <option
                          value="SI +386"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          SI +386
                        </option>
                        <option
                          value="SE +46"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          SE +46
                        </option>
                        <option
                          value="SZ +268"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          SZ +268
                        </option>
                        <option
                          value="SX +1721"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          SX +1721
                        </option>
                        <option
                          value="SC +248"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          SC +248
                        </option>
                        <option
                          value="TC +1"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          TC +1
                        </option>
                        <option
                          value="TD +235"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          TD +235
                        </option>
                        <option
                          value="TG +228"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          TG +228
                        </option>
                        <option
                          value="TH +66"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          TH +66
                        </option>
                        <option
                          value="TJ +992"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          TJ +992
                        </option>
                        <option
                          value="TK +690"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          TK +690
                        </option>
                        <option
                          value="TM +993"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          TM +993
                        </option>
                        <option
                          value="TL +670"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          TL +670
                        </option>
                        <option
                          value="TO +676"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          TO +676
                        </option>
                        <option
                          value="TT +1868"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          TT +1868
                        </option>
                        <option
                          value="TN +216"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          TN +216
                        </option>
                        <option
                          value="TR +90"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          TR +90
                        </option>
                        <option
                          value="TV +688"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          TV +688
                        </option>
                        <option
                          value="TW +886"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          TW +886
                        </option>
                        <option
                          value="TZ +255"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          TZ +255
                        </option>
                        <option
                          value="UG +256"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          UG +256
                        </option>
                        <option
                          value="UA +380"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          UA +380
                        </option>
                        <option
                          value="UM +1"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          UM +1
                        </option>
                        <option
                          value="UY +598"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          UY +598
                        </option>
                        <option
                          value="US +1"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          US +1
                        </option>
                        <option
                          value="UZ +998"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          UZ +998
                        </option>
                        <option
                          value="VA +39"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          VA +39
                        </option>
                        <option
                          value="VC +1"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          VC +1
                        </option>
                        <option
                          value="VE +58"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          VE +58
                        </option>
                        <option
                          value="VG +1"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          VG +1
                        </option>
                        <option
                          value="VI +1"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          VI +1
                        </option>
                        <option
                          value="VN +84"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          VN +84
                        </option>
                        <option
                          value="VU +678"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          VU +678
                        </option>
                        <option
                          value="WF +681"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          WF +681
                        </option>
                        <option
                          value="WS +685"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          WS +685
                        </option>
                        <option
                          value="YE +967"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          YE +967
                        </option>
                        <option
                          value="ZA +27"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          ZA +27
                        </option>
                        <option
                          value="ZM +260"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          ZM +260
                        </option>
                        <option
                          value="ZW +263"
                          className="R4tv1w"
                          aria-selected="false"
                        >
                          ZW +263
                        </option>
                      </select>
                    </div>
                    <div className="col-md-9">
                      <label htmlFor="phone" className="form-label">
                        Phone<span className="text-danger">*</span>
                      </label>
                      <input
                        type="tel"
                        name="phone"
                        className="form-control"
                        placeholder="Phone Number"
                        value={formData.phone}
                        onChange={handleChange}
                        required
                      />
                    </div>
                  </div>
                </div>

                <div className="mb-3">
                  <label htmlFor="company" className="form-label">
                    Company
                  </label>
                  <input
                    type="text"
                    name="company"
                    className="form-control"
                    placeholder="Your Company"
                    value={formData.company}
                    onChange={handleChange}
                  />
                </div>

                <div className="mb-3">
                  <label htmlFor="message" className="form-label">
                    Message
                  </label>
                  <textarea
                    className="form-control"
                    name="message"
                    rows="3"
                    placeholder="Your Message"
                    value={formData.message}
                    onChange={handleChange}
                  ></textarea>
                </div>

                <div className="mb-3">
                  <button
                    type="submit"
                    className="btn btn-primary"
                    disabled={loading}
                  >
                    {loading ? (
                      <>
                        <Spinner animation="border" size="sm" />
                        <span className="ms-2">Sending...</span>
                      </>
                    ) : (
                      "SEND"
                    )}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};

export default GetQuote;