import React, { useState, useEffect } from "react";
import "../css/Legends.css";
import Header from "../component/Header";
import Footer from "../component/Footer";
import "../css/Spotlightwall.css";
import AOS from "aos";
import "aos/dist/aos.css";
import axios from "axios";
import { BASE_SERVER, BASE_SERVER_URL } from "../utils/Api";
import { Link } from "react-router-dom";

const Spotlightwall = () => {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  const [glbFiles, setGlbFiles] = useState([]);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [timeoutError, setTimeoutError] = useState(false);

  useEffect(() => {
    fetchImagesFromAPI();
  }, []);

  const fetchImagesFromAPI = async () => {
    setIsLoading(true);
    setTimeoutError(false);

    const timer = setTimeout(() => {
      setIsLoading(false);
      setTimeoutError(true);
    }, 45000);

    try {
      const response = await axios.get(`${BASE_SERVER_URL}/images`);
      const arr = response.data.posts.reverse().map((element) => ({
        frontImg: `${BASE_SERVER}/${element.frontImg}`,
        backImg: `${BASE_SERVER}/${element.backImg}`,
        textData: element.textData,
        textLink: element.textLink,
        id: element._id,
      }));

      setGlbFiles(arr);
      setIsLoading(false);
      clearTimeout(timer);
    } catch (error) {
      // console.error("Error fetching images from API:", error);
      const errorMessage = error.response
        ? "Failed to load images from the API. Please try again later."
        : "Network error. Please check your connection and try again.";
      setError(errorMessage);
      setIsLoading(false);
      clearTimeout(timer);
    }
  };

  return (
    <div
      style={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}
    >
      <Header />
      <div
        className="container spotlight-container"
        style={{ marginBottom: "120px", flex:"1" }}
      >
        <div className="row p-5 text-center">
          <h1 style={{ color: "#22B1DF" }} data-aos="zoom-out-down">
            Our Legends - You too be a part of this journey
          </h1>
          <p className="spotlight-paragraph"
          >
            Welcome to the FreeHydration Spotlight Wall, where we honor those who have significantly contributed
            to our mission of providing free and accessible water for everyone. Each individual on this list
            has generously supported our efforts in making free hydration a reality. Whether the contribution
            is large or small, every support plays a vital role in advancing the belief that free hydration is a
            fundamental human right, not a privilege. We take pride in recognizing these individuals by inducting
            them into our Spotlight Wall, where their names will reflect as a testament to their commitment to creating a better world.
          </p>
        </div>

        <div className="container spotlight-container d-flex flex-wrap">
          {/* Loading Spinner */}
          {isLoading && (
            <div className="spinner-container">
              <div className="spinner"></div>
            </div>
          )}

          {/* Timeout Error Message */}
          {timeoutError && (
            <p style={{ color: "red", width: "100%", textAlign: "center" }}>
              Data is not available at the moment. Please refresh or try again
              later.
            </p>
          )}

          {/* Conditional Content for Images */}
          {!isLoading && !timeoutError && (
            <>
              {error && <p style={{ color: "red" }}>{error}</p>}

              {/* Check if there is no image data */}
              {glbFiles.length === 0 ? (
                <p style={{ color: "red", width: "100%", textAlign: "center" }}>
                  No image data available.
                </p>
              ) : (
                // If image data exists, map through the data and display the cans
                glbFiles.map((data, index) => (
                  <div
                    key={index}
                    className="can-container"
                    style={{ margin: "20px 10px" }}
                  >
                    <div className="product">
                      {/* Apply front and back images to the same soda element */}
                      <div
                        className="soda sidi"
                        style={{
                          "--front-url": `url(${data.frontImg})`,
                          "--back-url": `url(${data.backImg})`,
                        }}
                      ></div>
                    </div>
                    <div className="text-data">
                      {data.textData ? (
                        <p>
                          <Link
                            to={data.textLink ? data.textLink : "#"}
                            target="_blank"
                            style={{
                              textDecoration: "none",
                              fontSize: "18px",
                              fontWeight: "bold",
                            }}
                          >
                            {data.textData}
                          </Link>
                        </p>
                      ) : (
                        <p>No Text Data Available</p>
                      )}
                    </div>
                  </div>
                ))
              )}
            </>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Spotlightwall;
