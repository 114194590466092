import React from 'react';
import 'aos/dist/aos.css';
import '../css/YourComponent.css';
import img from '../images/FreeHydration_enhanced.png'
const YourComponent = () => {
  return (
    <div className="container-fluid" id='Advertise'>
      <div className="container" >
        <div className="row p-3">
          <div className="col-md-6">
            <img
              src={img}
              alt="Your Brand"
              className="img-fluid"
              data-aos="zoom-in-down"
              style={{width:"80%"}}
            />
          </div>
          <div
            className="col-md-6 d-flex flex-column justify-content-center align-items-center"
          >
            <h1
              className="text-center"
              style={{ color: '#22B1DF' }}
              data-aos="zoom-in-down"
            >
              Your Brand In Everyone's Hand!
            </h1>
            <p
              className="text-center"
              style={{ color: '#22B1DF' }}
              data-aos="zoom-in-down"
            >
              Acquire significant impressions and harness the foremost brand loyalty platform within the advertising industry.
            </p>
            <div className="button-container mt-4">
              <a href="#getQuote" className="btn" data-aos="zoom-in-down">
                GET A QUOTE
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default YourComponent;