import React, { useEffect, useState } from 'react';
import { Tab, Nav, Accordion } from 'react-bootstrap';
import 'aos/dist/aos.css'; 
import Footer from '../component/Footer';
import Header from '../component/Header';
import AOS from 'aos';
const FAQSection = () => {
  const [activeKey, setActiveKey] = useState('home');
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);
  return (
    <div
      className="main-body-faq"
      style={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}
    >
      <Header />
      <div className="container mb-5 Faq-sectionpart" style={{ flex: "1" }}>
        <div className="row p-5 text-center">
          <h1 style={{ color: "#22B1DF" }} data-aos="zoom-out-down">
            Frequently Asked Questions
          </h1>
        </div>
        <div className="row">
          <div className="col-md-12">
            <Tab.Container
              id="left-tabs-example"
              activeKey={activeKey}
              onSelect={(k) => setActiveKey(k)}
            >
              <Nav variant="tabs" className="mb-3">
                <Nav.Item>
                  <Nav.Link eventKey="home">General</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="profile">Advertising</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="other">Other</Nav.Link>
                </Nav.Item>
              </Nav>
              <Tab.Content style={{ marginBottom: "66px" }}>
                <Tab.Pane eventKey="home">
                  <Accordion defaultActiveKey="0">
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>
                      How is FreeHydration water offered free of charge? 
                      </Accordion.Header>
                      <Accordion.Body>
                      Our water is financed through the advertisements featured prominently on the packaging.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                      <Accordion.Header>
                        What are the packaging materials?
                      </Accordion.Header>
                      <Accordion.Body>
                        <p>
                          <strong>Aluminium Cans</strong>
                        </p>
                        <p>
                          Aluminum is the most recyclable packaging material and boasts the highest recycling rate worldwide,
                          rendering our cans considerably more environmentally sustainable than single-use plastics.
                          Furthermore, they present a more elegant and visually appealing alternative to conventional plastic bottles.
                        </p>
                        <p>
                          <strong>Paper-Based Cartons</strong>
                        </p>
                        <p>
                          Our cartons are composed entirely of renewable materials. The main body is crafted from wood fibers sourced from responsibly managed forests,
                          while the shoulder and cap are derived from sugarcane.Both materials are fully renewable and capable of regeneration, which effectively
                          minimizes our carbon footprint.
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                      <Accordion.Header>
                      What is the estimated time to place an order?
                      </Accordion.Header>
                      <Accordion.Body>
                        <p>
                          <strong>Aluminium Cans</strong>
                        </p>
                        <p>
                          The typical lead time is approximately 2-4 weeks from the moment of artwork approval.
                          However, if you're facing a tight deadline, we may be able to expedite your order for an additional fee.
                        </p>
                        <p>
                          <strong>Paper-Based Cartons</strong>
                        </p>
                        <p>Likewise, orders for paper-based cartons generally require about 2-4 weeks from the time of artwork approval.</p>
                        <p><strong style={{color:"red"}}>Note:</strong> Please note that timelines may vary under certain circumstances for either of the categories,
                          which will be clarified at the time of order confirmation. </p>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="3">
                      <Accordion.Header>
                      How can I contribute to the growth of FreeHydration?
                      </Accordion.Header>
                      <Accordion.Body>
                        If you’re interested in becoming a part of the FreeHydration
                        family and contributing to our expansion, we invite you to
                        help spread our message by sharing it on social media with your
                        friends and family. Furthermore, consider subscribing to our
                        newsletter, where you can engage in exciting giveaways,
                        receive motivational content, and stay informed about all
                        the latest developments related to FreeHydration.
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </Tab.Pane>
                <Tab.Pane eventKey="profile">
                  <Accordion defaultActiveKey="0">
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>What are the steps to advertise with FreeHydration?</Accordion.Header>
                      <Accordion.Body>
                        To begin your advertising collaboration with FreeHydration,
                        please visit our advertising page and complete the "Get a Quote"
                        form, providing all the required details. We are committed to
                        getting back to you as swiftly as possible.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                      <Accordion.Header>
                      In what ways does advertising with FreeHydration stand apart from traditional marketing methods?
                      </Accordion.Header>
                      <Accordion.Body>
                        Traditional marketing approaches, such as Television,
                        Social-Media, Newspaper or Radio, frequently create
                        inconvenience for consumers, leading many to avoid or
                        dismiss these interruptions. In contrast, FreeHydration
                        utilizes a distinctive advertising model where consumers
                        proactively seek out our product, creating a natural affinity
                        for your advertisement featured on the packaging. As they Savor
                        our water, they are simultaneously engaging with your brand
                        message, enhancing the likelihood of retention and positive association.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                      <Accordion.Header>
                      Where can I promote my brand with FreeHydration?
                      </Accordion.Header>
                      <Accordion.Body>
                        With FreeHydration, you can effectively showcase your
                        brand across the Republic of Ireland. We have plans to
                        launch FreeHydration in an exciting new region in the near
                        future. Subscribe to our newsletter to receive timely updates!
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </Tab.Pane>
                <Tab.Pane eventKey="other">
                  <Accordion defaultActiveKey="0">
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>
                      How can I request FreeHydration to provide water at my event?
                      </Accordion.Header>
                      <Accordion.Body>
                      If you would like us to provide water at your event, please reach out through the contact form on our website.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                      <Accordion.Header>
                      I have further inquiries.
                      </Accordion.Header>
                      <Accordion.Body>
                      If you have any additional questions regarding FreeHydration, please complete our contact form, and we will respond to you promptly.
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default FAQSection;
