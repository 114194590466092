import React, { useState, useEffect, useCallback } from 'react';
import '../css/Login.css';
import Header from '../component/Header';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useNavigate } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
import Swal from 'sweetalert2'; // Import SweetAlert2
import Footer from '../component/Footer';
import { BASE_SERVER_URL } from '../utils/Api';
import { FaEye, FaEyeSlash } from "react-icons/fa";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [showhidePass, setShowHidePass] = useState(false);
  const navigate = useNavigate();

  const toggleShowPassVisibility = () => {
    setShowHidePass((prevState) => !prevState);
  };

  const checkTokenValidity = useCallback(() => {
    const token = localStorage.getItem("token");
    const expiryTime = localStorage.getItem("expiry");
    const currentTime = new Date().getTime();

    if (token && expiryTime && currentTime > expiryTime) {
      logoutUser();
    }
  }, []);

  const logoutUser = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("role");
    localStorage.removeItem("expiry");
    Swal.fire('Error', 'Session has expired. Please log in again.', 'error'); // Show error popup
    navigate("/login");
  };

  useEffect(() => {
    AOS.init({ duration: 1000, once: true });
    AOS.refresh();
    checkTokenValidity();
    const interval = setInterval(checkTokenValidity, 60 * 1000);
    return () => clearInterval(interval);
  }, [checkTokenValidity]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const response = await fetch(`${BASE_SERVER_URL}/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, password }),
      });

      const data = await response.json();

      if (response.ok) {
        const expiryTime = new Date().getTime() + 2 * 60 * 1000; // 30 minutes from now
        localStorage.setItem("token", data.token);
        localStorage.setItem("role", data.user.role);
        localStorage.setItem("expiry", expiryTime);

        Swal.fire('Success', 'Login successful!', 'success'); // Fixed success message
        setTimeout(() => {
          navigate("/Dashboard");
        }, 1000);
      } else {
        Swal.fire('Error', data.message || "Login failed", 'error'); // Specify error type
        setLoading(false);
      }
    } catch (error) {
      Swal.fire('Error', 'An error occurred. Please try again.', 'error'); // Show error popup
      setLoading(false);
    }
  };

  return (
    <>
      <Header />
      <div className="login-container">
        <form className="login-form" onSubmit={handleSubmit}>
          <h2 className="login-title">Login</h2>

          <div className="input-group" data-aos="zoom-out-down">
            <label htmlFor="email" className="input-label">Email</label>
            <input
              type="email"
              id="email"
              className="input-field"
              placeholder="Enter your email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              autoComplete="username"
            />
          </div>

          <div className="input-group" data-aos="zoom-out-down">
            <label htmlFor="password" className="input-label">Password</label>
            <div className="password-container">
              <input
                type={showhidePass ? "text" : "password"}
                id="password"
                className="input-field"
                placeholder="Enter your password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
                autoComplete="current-password"
              />
              <span className="eye-icon" onClick={toggleShowPassVisibility}>
                {showhidePass ? <FaEyeSlash /> : <FaEye />}
              </span>
            </div>
          </div>

          <button
            type="submit"
            className="login-button"
            data-aos="zoom-out-down"
            disabled={loading}
          >
            {loading ? (
              <>
                <Spinner animation="border" size="sm" />
                <span className="ms-2">Login...</span>
              </>
            ) : (
              "Login"
            )}
          </button>
        </form>
      </div>
      <Footer />
    </>
  );
};

export default Login;
