import React, { useState } from 'react';
import axios from 'axios';
import { Button, Form, Container, Row, Col, Alert, Spinner } from 'react-bootstrap';
import Swal from 'sweetalert2';
import { BASE_SERVER_URL } from '../utils/Api';

const SubscribeForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    city: '',
  });

  const [formError, setFormError] = useState('');
  const [formSuccess, setFormSuccess] = useState('');
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const validateEmail = (email) => {
    const regex = /^[a-zA-Z0-9._-]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };
  

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("Form submitted:", formData);

    if (!formData.name || !formData.email || !formData.city) {
      setFormError('All fields are required.');
      return;
    }

    if (!validateEmail(formData.email)) {
      setFormError('Please enter a valid email address.');
      return;
    }

    setFormError('');
    setFormSuccess('');
    setLoading(true);

    try {
      const apiUrl = `${BASE_SERVER_URL}/subscription`;
      const response = await axios.post(apiUrl, formData);
      if (response.status === 200) {
        setFormSuccess('Subscribed successfully!');
        Swal.fire('Success', response.data.message, 'success');
        // console.log('Form submitted:', response.data);


        setFormData({
          name: '',
          email: '',
          city: '',
        });
      }
    } catch (error) {
      setFormError('An error occurred. Please try again.');
      // console.error('Error submitting form:', error);
      Swal.fire('Error', 'An error occurred. Please try again.', 'error');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="mt-5 mb-5">
      <Container>
        <Row>
          <Col md={8} className="offset-md-2">
            <div className="shadow p-5 mb-5 bg-white rounded">
              <Form onSubmit={handleSubmit} id="myformOne">
                <h2 className="text-center mb-4 mt-4" data-aos="zoom-out-down" style={{ color: '#22B1DF' }}>
                  Subscribe To Get Your FreeHydration
                </h2>
                <div style={{ borderTop: '1px solid #eee', marginBottom: '40px' }}></div>

                {formError && <Alert variant="danger">{formError}</Alert>}
                {formSuccess && <Alert variant="success">{formSuccess}</Alert>}

                <Form.Group className="mb-3" data-aos="zoom-out-down">
                  <Form.Label>
                    Name<span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="name"
                    placeholder="First Name"
                    value={formData.name}
                    onChange={handleChange}
                    style={{ borderBottom: '2px solid #22B1DF' }}
                    required
                  />
                </Form.Group>

                <Form.Group className="mb-3" data-aos="zoom-out-down">
                  <Form.Label>
                    Email<span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    type="email"
                    name="email"
                    placeholder="Email"
                    value={formData.email}
                    onChange={handleChange}
                    style={{ borderBottom: '2px solid #22B1DF' }}
                    required
                  />
                </Form.Group>

                <Form.Group className="mb-3" data-aos="zoom-out-down">
                  <Form.Label>
                    City<span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="city"
                    placeholder="City"
                    value={formData.city}
                    onChange={handleChange}
                    style={{ borderBottom: '2px solid #22B1DF' }}
                    required
                  />
                </Form.Group>

                <div className="button-container">
                  <Button type="submit" className="btn" data-aos="zoom-in-down"
                    disabled={loading}>
                    {loading ? (
                      <>
                        <Spinner animation="border" size="sm" />
                        <span className="ms-2">Subscribing...</span>
                      </>
                    ) : (
                      "Subscribe"
                    )}
                  </Button>
                </div>
              </Form>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default SubscribeForm;
